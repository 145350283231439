.assessment_items {
  display: flex;
  padding: 5px;
  flex-direction: column;
}

.assessment_item {
  border-bottom: 1px solid black;
  padding-top: 20px;
  padding-bottom: 20px;
}
