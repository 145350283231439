@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url("../fonts/lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/lato/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url("../fonts/lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/lato/Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  font-weight: bold;
  src: url("../fonts/lato/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/lato/Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: url("../fonts/lato/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/lato/Lato-BlackItalic.ttf") format("truetype");
}

body {
  font-family: 'Lato', sans-serif;
}
