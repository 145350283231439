// Materia 4.1.1
// Bootswatch

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: #666 !default;
$gray-700: #444 !default;
$gray-800: #222 !default;
$gray-900: #212121 !default;
$black: #000 !default;

$blue: #2196F3 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e51c23 !default;
$orange: #fd7e14 !default;
$yellow: #ff9800 !default;
$green: #4CAF50 !default;
$teal: #20c997 !default;
$cyan: #9C27B0 !default;

$primary: $blue !default;
$secondary: $white !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white !default;
$dark: $gray-800 !default;

// Body

$body-color: $gray-700 !default;

// Fonts

$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$font-size-base: 0.8125rem !default;

$font-weight-base: 400 !default;

// Buttons

$input-btn-padding-y: 1rem !default;
$input-btn-padding-x: 1rem !default;

// Forms

$input-padding-y: 1rem !default;
$input-padding-x: 0 !default;

$input-padding-y-sm: $font-size-base !default;
$input-padding-x-sm: 0 !default;

$input-padding-y-lg: ($font-size-base * 1.25) !default;
$input-padding-x-lg: 0 !default;

$input-bg: transparent !default;
$input-disabled-bg: transparent !default;

$input-color: $gray-600 !default;
$input-border-color: transparent !default;
$input-border-width: 0rem !default;

$input-border-radius: 0 !default;
$input-border-radius-lg: 0 !default;
$input-border-radius-sm: 0 !default;

$input-placeholder-color: rgba(0, 0, 0, 0.4) !default;

$input-group-addon-bg: transparent !default;

$custom-control-gutter: 1.75rem !default;

$custom-control-indicator-focus-box-shadow: 0 !default;

$custom-control-indicator-size: 1.25rem !default;
$custom-control-indicator-bg: $white;
$custom-checkbox-indicator-border-radius: 2px !default;

$custom-control-indicator-disabled-bg: $gray-100 !default;

$custom-radio-indicator-icon-checked: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='4' fill='%232196F3'/%3E%3C/svg%3E") !default;

// Navs

$nav-link-disabled-color: $gray-500 !default;

$nav-tabs-border-color: transparent !default;

// Navbar

$navbar-padding-y: 1rem !default;

$navbar-dark-color: rgba($white, .75) !default;
$navbar-dark-hover-color: $white !default;

// Cards

$card-border-width: 0;
$card-border-color: transparent;

// Tooltips

$tooltip-bg: $gray-700 !default;

// Modals

$modal-content-border-color: transparent !default;

// Progress bars

$progress-height: 0.375rem !default;
$progress-border-radius: 0 !default;

// Close

$close-font-size: 2.125rem !default;
$close-font-weight: 300 !default;
