@import "variables";
@import "themes/materia/variables";
@import "bootstrap";
@import "themes/materia/bootswatch";
@import "fonts";
@import "~spin.js/spin.css";

html, body, div#root, div.container {
  height: 100%;
}

.btn {
  font-weight: bold;
}
